<template>
  <div class="table-responsive">
    <template v-if="data.length > 1">
      <template v-if='isMobile(true)'>
        <!-- Head Section -->
        <div class="headSection" :class="{'adtcolorbk' : develop == 'adt'}">
          <b-row class="container" style="margin:auto">
            <b-col cols="4" class="datePad" >Date</b-col>
            <b-col cols="8">Tournament & Venue</b-col>
          </b-row>
        </div>
        <!-- Body Section -->
        <div v-for="(month, index) in months" :key="index" >
          <div class="monthRow" v-if="month.value != 'Jan'">
            <div class="container monthindex">{{month.full_name}} {{id}}</div>
          </div>
          <template v-if="develop == 'adt'">
            <b-row v-for="(item, index) in filteredList" :key="index" class="container datarow" v-show="item.dic_dates.split('-')[0].includes(month.value)" :filter="filter">
              <b-col>
                <b-link :to="'/adt/tournament/' + item.code + '/' + id">
                  <b-row>
                    <b-col class="greyFont datePad" cols="4">
                      {{item.dic_dates}}
                      <hr v-if="item.code == code" class="hrLineMob">
                    </b-col>
                    <b-col class="blueFont" cols="8">
                      <b-link :to="'/adt/tournament/' + item.code + '/' + id" class="linkColoradt">
                          <span v-if="item.full_name !== ''">
                            {{item.full_name}}
                          </span>
                          <span v-else>
                            {{item.short_name}}
                          </span>
                        <span v-if="item.oom_sponsor_url.length > 0">
                          <img class="oomSponUrl" :src="item.oom_sponsor_url">
                        </span>
                      </b-link>
                      <div class="greyFont">
                        {{item.course}}
                        <span class="courseCountry">{{item.course_country}}</span>
                        {{item.total_prize_fund}}
                        <div class="blueFont">
                          <template v-if="item.profile == 'Y'">
                            <b-link class="linkColoradt" :to="'/adt/playerprofile/' + item.winner_ref" :class="{major : item.major == 'Y'}" >
                              {{item.winner_name}}
                            </b-link>
                          </template>
                          <template v-else>
                          <b-item :class="{major : item.major == 'Y'}" class="noLink" >
                            {{item.winner_name}}
                          </b-item>
                          </template>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-link>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row v-for="(item, index) in filteredList" :key="index" class="container datarow" v-show="item.dic_dates.split('-')[0].includes(month.value)" :filter="filter">
              <b-col>
                <b-link :to="'/tournament/' + item.code + '/' + id">
                  <b-row>
                    <b-col class="greyFont datePad" cols="4">
                      {{item.dic_dates}}
                      <hr v-if="item.code == code" class="hrLineMob">
                    </b-col>
                    <b-col class="blueFont" cols="8">
                      <b-link :to="'/tournament/' + item.code + '/' + id" class="linkColor">
                        <span v-if="item.full_name !== ''">
                          {{item.full_name}}
                        </span>
                        <span v-else>
                          {{item.short_name}}
                        </span>
                        <span v-if="item.oom_sponsor_url.length > 0">
                          <img class="oomSponUrl" :src="item.oom_sponsor_url">
                        </span>
                      </b-link>
                      <div class="greyFont">
                        {{item.course}}
                        <span class="courseCountry">{{item.course_country}}</span>
                        {{item.total_prize_fund}}
                        <div class="blueFont">
                          <template v-if="item.profile == 'Y'">
                            <b-link class="linkColor" :to="'/playerprofile/' + item.winner_ref" :class="{major : item.major == 'Y'}" >
                              {{item.winner_name}}
                            </b-link>
                          </template>
                          <template v-else>
                          <b-item :class="{major : item.major == 'Y'}" class="noLink" >
                            {{item.winner_name}}
                          </b-item>
                          </template>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-link>
              </b-col>
            </b-row>
          </template>
        </div>
      </template>
      <template v-else>
        <!-- Head Section -->
        <div class="headSection" :class="{'adtcolorbk' : develop == 'adt'}">
          <b-row class="container" style="margin:auto">
            <b-col class="datePad" >Date</b-col>
            <b-col col lg="3" sm="4">Tournament</b-col>
            <b-col col lg="3" sm="4">Venue</b-col>
            <b-col class="d-none d-md-block">Prize Fund</b-col>
            <b-col class="d-none d-md-block">Winner</b-col>
            <b-col class="d-none d-md-block"></b-col>
          </b-row>
        </div>
        <!-- Body Section -->
        <div v-for="(month, index) in months" :key="index" >
          <div :class="{'adtDevider' : develop == 'adt'}" class="monthRow" v-for="(value) in tournDates" :key="value" v-show="value.includes(month.value)">
            <div class="container monthindex">{{month.full_name}} {{id}}</div>
          </div>
          <b-row v-for="(item, index) in filteredList" :key="index" class="container datarow" v-show="item.dic_dates.split('-')[0].includes(month.value)" :filter="filter">
            <template v-if="item.code == code">
              <template v-if="develop == 'adt'">
                <div :class="{ current_tourn_rowadt: item.code == code }"></div> 
              </template>
              <template v-else>
                <div :class="{ current_tourn_row: item.code == code }"></div> 
              </template>
            </template>
            <b-col class="greyFont datePad" >
              {{item.dic_dates}}
            </b-col>
            <b-col class="blueFont" col lg="3" sm="4">
              <template v-if="develop == 'adt'">
                <b-link :to="'/adt/tournament/' + item.code + '/' + id" class="linkColoradt">
                  <span v-if="item.full_name !== ''">
                    {{item.full_name}}
                  </span>
                  <span v-else>
                    {{item.short_name}}
                  </span>
                  <span v-if="item.oom_sponsor_url.length > 0">
                    <img class="oomSponUrl" :src="item.oom_sponsor_url">
                  </span>
                </b-link>
              </template>
              <template v-else>
                <b-link :to="'/tournament/' + item.code + '/' + id" class="linkColor">
                  <span v-if="item.full_name !== ''">
                    {{item.full_name}}
                  </span>
                  <span v-else>
                    {{item.short_name}}
                  </span>
                  <span v-if="item.oom_sponsor_url.length > 0">
                    <img class="oomSponUrl" :src="item.oom_sponsor_url">
                  </span>
                </b-link>
              </template>
            </b-col>
            <b-col class="greyFont" col lg="3" sm="4">
              {{item.course}}
              <span class="courseCountry">{{item.course_country}}</span>
            </b-col>
            <b-col class="greyFont d-none d-md-block" >{{item.total_prize_fund}}</b-col>
            <b-col class="blueFont d-none d-md-block">
                <template v-if="item.profile == 'Y'">
                  <template v-if="develop == 'adt'">
                    <b-link class="linkColoradt" :to="'/adt/playerprofile/' + item.winner_ref" :class="{major : item.major == 'Y'}" >
                      {{item.winner_name}}
                    </b-link>
                  </template>
                  <template v-else>
                    <b-link class="linkColor" :to="'/playerprofile/' + item.winner_ref" :class="{major : item.major == 'Y'}" >
                      {{item.winner_name}}
                    </b-link>
                  </template>
                </template>
                <template v-else>
                <b-item :class="{major : item.major == 'Y'}" class="noLink" >
                  {{item.winner_name}}
                </b-item>
                </template>
            </b-col>
            <b-col class="d-none d-md-block">
              <span v-if="item.ts_final_result == 'Y'">
                <template v-if="develop == 'adt'">
                  <b-link class="textInfoadt"
                  :to="{
                    name: 'adt-reports',
                    query: {
                      url:
                        'https://asian.ocs-asia.com/tic/tmresult.cgi?tourn=' +
                        item.code +
                        '~season=' +
                        id +
                        '~result=PF~',
                      id: id,
                      code: item.code,
                      title: 'Final Result',
                    },
                  }"                  
                  >
                    Results
                    <span class="arrowRight">
                      <img class="RightArrow" src="https://assets.asiantour.com/asian-tour/2024/05/next-arrow_LightGreen.png">
                    </span>
                  </b-link>
                </template>
                <template v-else>
                  <b-link class="textInfo"
                  :to="{
                    name: 'reports',
                    query: {
                      url:
                        'https://asian.ocs-asia.com/tic/tmresult.cgi?tourn=' +
                        item.code +
                        '~season=' +
                        id +
                        '~result=PF~',
                      id: id,
                      code: item.code,
                      title: 'Final Result',
                    },
                  }"                  
                  >
                    Results
                    <span class="arrowRight">
                      <img class="RightArrow" src="https://assets.asiantour.com/asian-tour/2024/05/arrow.png">
                    </span>
                  </b-link>
                </template>
              </span>
              <span v-else>
                <template v-if="develop == 'adt'">
                  <b-link :to="'/adt/tournament/' + item.code + '/' + id" class="textInfoadt Infotag">
                    <span class="infoBlock">
                      <img class="info" src="https://assets.asiantour.com/asian-tour/2024/05/Info_LightGreen.png">
                    </span>
                    Info
                  </b-link>
                </template>
                <template v-else>
                  <b-link :to="'/tournament/' + item.code + '/' + id" class="textInfo Infotag">
                    <span class="infoBlock">
                      <img class="info" src="https://assets.asiantour.com/asian-tour/2024/05/Info.png">
                    </span>
                    Info
                  </b-link>
                </template>
              </span>
            </b-col>
          </b-row>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "scheduleTable",
  props: ["fields", "data", "filter", "code", "config", "months", "id", "search", "develop", "tournDates"],
  methods: {
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (this.code === item.code) return "live";
    },
    isMobile() {
      if( screen.width <= 990 ) {
        return true;
      }
      else {
        return false;
      }
    },
  },
  computed: {
    filteredList() {
      return this.data.filter(item => {
        return item.full_name.toLowerCase().includes(this.search);
      })
    }
  }
};
</script>

<style scoped>
.monthRow.adtDevider {
  background-color: #1c857c;
}
::v-deep .adtcolorbk {
  background-color: #76c3bd!important;
}
/* New table code */
.current_tourn_row {
  height: 50px;
  background: #5897E6;
  display: block;
  width: 5px;
  border-radius: 5px;
}
.row.container.datarow.current_tourn_row {
  border-left: 5px solid #5897E6;
}
.current_tourn_rowadt {
  height: 50px;
  background: #76c3bd;
  display: block;
  width: 5px;
  border-radius: 5px;
}
.row.container.datarow.current_tourn_rowadt {
  border-left: 5px solid #76c3bd;
}
.datePad {
  padding-left: 8px;
}
.monthRow {
  background-color: #A8B7C9;
  color: #fff!important;
  font-size: 13px;
  font-weight: 500;
  height: 47px;
  line-height: 3.6;
}
.monthindex {
  margin: auto;
  padding-left: 25px;
}
::v-deep a.linkColor {
  color:#042F62!important
}
::v-deep a.linkColor:hover {
  color:#5897E6!important;
  text-decoration: none;
}
::v-deep a.linkColoradt {
  color:#76c3bd!important
}
::v-deep a.linkColoradt:hover {
  color:#1c857c!important;
  text-decoration: none;
}
.datarow {
  margin: auto;
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #f1eded;
}
.blueFont {
  color: #042F62;
  font-size: 12px;
  font-weight: 500;
}
.greyFont {
  color: #7E7E7E;
  font-size: 12px;
  font-weight: 500;
}
.headSection {
  background-color: #042F62;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  height: 47px;
  line-height: 3.6;
}
.table-responsive {
  background: white;
}
a.textInfo.Infotag {
  float: right;
  /* right: 83px; */
}
a.textInfoadt.Infotag {
  float: right;
  /* right: 83px; */
}
.noLink {
  color: #7E7E7E; 
}
td.dicDates {
  color: #7E7E7E;
  font-size: 12px;
  padding-left: 12px;
}
td.fullname {
  width: 250px;
}
td.fullname > a{
  color: #0A3F7F!important;
  font-weight: bold;
}
td.fullname >a:hover {
  color: #5897E6!important;
  text-decoration: none;
}
td.courseCountrysec {
  color: #7E7E7E;
  font-size: 12px;
  width: 280px;
}
span.course_country {
  display: block;
  font-weight: 200;
}
td.prizeMoney {
  color: #7E7E7E;
}
td.winner_name > a {
  color: #0A3F7F!important;
  font-weight: 500;
}
td.winner_name > a:hover {
  color: #5897E6!important;
  font-weight: 500;
  text-decoration: none;
}
td {
  padding-top: 20px;
  padding-bottom: 20px;
}
th {
  color: white;
  background-color: #133f7b;
  padding: 0px;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
}
::v-deep th:first-child {
  /* padding-left: 21.5em; */
  /* padding-right: 10em; */
}
::v-deep th:last-child {
  /* padding-right: 22em */
}
td.ResultsText {
  font-size: 10px;
  color: #0A3F7F;
  text-transform: uppercase;
  font-weight: 500;
}
.infoBlock {
  display: block;
}
.info {
  width: 15px;
  margin-left: 4px;
  padding-bottom: 6px;
}
.RightArrow {
  width: 15px;
  margin-top: -3px;
}
.arrowRight {
  padding-left: 10px;
}
tr.monthRow > td{
  background-color: #A8B7C9;
  color: #fff!important;
  font-size: 13px;
  font-weight: bold;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
}
tr > td:first-child{
  color: #7E7E7E;
  /* padding-left: 23.3em; */
}
a.textInfo {
  color: #0A3F7F!important;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  top: 0px;
  float: right;
}
a.textInfo:hover {
  color: #5897E6!important;
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
}
a.textInfoadt {
  color: #76c3bd!important;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  top: 0px;
  float: right;
}
a.textInfoadt:hover {
  color: #1c857c!important;
  font-weight: 500;
  text-decoration: none;
  font-size: 12px;
}
@media only screen and (max-width: 990px) {
  .hideMob {
    display: none;
  }
  ::v-deep td.dicDates {
    color: #7E7E7E;
    font-size: 12px;
    padding-left: 12px;
    padding-right: 15px;
  }
}

/* End of new table code */


.courseCountry {
  display: block;
  font-size: 10pt;
  color: #7E7E7E;
  font-weight: 200;
}
.major {
  color: #133f7b!important;
  font-weight: bold!important;
}
.major > a {
  color: #133f7b!important;
  font-weight: bold!important;
}
::v-deep tr > td {
  border-top: 0px solid #dee2e6;
}
td.tournBold.tournName > li > a:hover {
  text-decoration: underline;
}
td.winnerName > li > a:hover {
  text-decoration: underline;
}
.oomSponUrl {
  width: 20px;
  margin-left: 5px;
}
td.tournBold.tournName > li > a.nav-link {
  margin-top: -8px;
  color: #0A3F7F!important;
}
td.winnerName > li > a.nav-link {
  margin-top: -8px;
  color: #0A3F7F!important;
}
li {
  list-style: none;
}
li.nav-item::marker {
  color: transparent;
}
.nav-link {
  margin-top: -2%;
}

::v-deep td > li > a {
  padding: 0.5rem 1rem 0.5rem 0;
}
::v-deep th:last-child {
  /* border-top-right-radius: 15px; */
}

::v-deep thead {
  color: white;
  background-color: #042F62;
  /* border-radius: 10px; */
}

::v-deep .table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 0;
}

::v-deep .live {
  color: #133f7b;
  background-color: #d4d4d4 !important;
}
::v-deep .live > td > li > a {
  color: #133f7b !important;
}

::v-deep .live > td > ul > div > button {
  color: #133f7b;
  background-color: white;
}
.developGreen {
  background-color: #76c3bd !important;
}
::v-deep .developGreenDeep > thead > tr > th {
  background-color: #76c3bd !important;
}
::v-deep .developGreenDeep > tbody > .live {
  background-color: #d4d4d4 !important;
}
::v-deep .addborder {
  border-bottom: 1px solid #eceeee;
}
hr.hrLineMob {
  height: 3px;
  background: #5897E6;
  display: block;
  /* width: 5px; */
  border-radius: 5px;
}
</style>
